<template>
	<div class="w-full p-2 mb-3 rounded shadow-md md:text-left order-card card">
		<router-link :to="{ name: 'userIndividualOrder', params: { user_id: auth.id, order_id: preOrder.id } }">
			<div class="flex flex-wrap p-2">
				<div class="flex flex-wrap items-center w-1/2">
					<span class="order-number">
						#{{ preOrder.id }}
					</span>
					<span class="order-status">
						{{ preOrder.status }} - {{ preOrder.orderType }}
					</span>
				</div>
				<div class="w-1/2 text-right">
					<span class="qty">
						QTY: {{ preOrder.itemCount }}
					</span>
					<span class="price">
						${{ preOrder.subtotal }}
					</span>
				</div>
			</div>

			<div class="flex flex-wrap p-2">
				<div class="w-full mb-2 lg:w-1/2">
					<div class="label">
						Business
					</div>

					<div class="order-resp">
						{{ preOrder.business.name }}
					</div>

					<div class="address">
						{{ preOrder.business.address }},<br>{{ preOrder.business.city }}, {{ preOrder.business.state }} {{ preOrder.business.zip }}
					</div>
				</div>
				<div class="w-1/3 mb-2 text-center lg:w-1/6">
					<div class="label">
						Ordered
					</div>
					<div class="order-resp">
						{{ datePlaced }}
					</div>
					<div class="address">
						{{ timePlaced }}
					</div>
				</div>
				<div class="w-1/3 mb-2 text-center lg:w-1/6">
					<div class="label">
						Requested
					</div>
					<div v-if="preOrder.timing.requested > 0">
						<div class="order-resp">
							{{ requestedDate }}
						</div>
						<div class="address">
							{{ requestedTime }}
						</div>
					</div>
					<div
						v-else
						class="order-resp"
					>
						ASAP
					</div>
				</div>
				<div class="w-1/3 mb-2 text-center lg:w-1/6">
					<div class="label">
						Estimated
					</div>
					<div
						v-if="preOrder.timing.estimated.start > 0"
						class="order-resp"
					>
						{{ estimatedTimeStart }} - {{ estimatedTimeEnd }}
					</div>
					<div
						v-else
						class="order-resp"
					>
						N/A
					</div>
				</div>
				<div class="w-full lg:text-right">
					<div class="view">
						View
					</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { formatUnixDate, formatUnixTime } from '@/utils/formatUnixTime.js'

export default {
	props: {
		preOrder: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		...mapGetters('auth', [
			'auth'
		]),
		requestedTime() {
			return formatUnixTime(this.preOrder?.timing?.requested, this.preOrder?.timing?.timezone)
		},
		requestedDate() {
			return formatUnixDate(this.preOrder?.timing?.requested)
		},
		timePlaced() {
			return formatUnixTime(this.preOrder?.timing?.placed, this.preOrder?.timing?.timezone)
		},
		datePlaced() {
			return formatUnixDate(this.preOrder?.timing?.placed)
		},
		estimatedTimeStart() {
			return formatUnixTime(this.preOrder?.timing?.estimated?.start)
		},
		estimatedTimeEnd() {
			return formatUnixTime(this.preOrder?.timing?.estimated?.end)
		}

	}
}
</script>

<style lang="scss" scoped>

	.order-card {
		a {
			color: #444;
		}
		.order-number {
			font-weight: 800;
			font-size: 1.25em;
			padding-right: 15px;
		}
		.order-status {
			text-transform: uppercase;
			font-size: 0.85em;
		}
		.address,
		.phone {
			font-size: 0.85em;
		}
		.qty {
			padding-right: 10px;
			color: #888;
			font-size: 0.85em;
		}
		.price {
			font-weight: 600;
			font-size: 1.25em;
		}
		.label {
			display: inline-block;
			width: 100%;
			text-transform: uppercase;
			color: #888;
			font-size: 0.85em;
			white-space: nowrap;
		}
		.order-resp {
			font-size: 1em;
			font-weight: 600;
		}
		.view {
			display: inline-block;
			padding: 5px 10px;
			border-radius: 4px;
			background: #c31f78;
			color: white;
			font-weight: 600;
			text-align: center;
		}

		@media (max-width: $break-md) {
			.view {
				width: 100%;
			}
		}
	}

</style>
